@import 'mixins';

// Here you can add other styles

.filter-close {
  border-radius: 2px;
  background: #b8c1d2;
  transform: translate(-10px, 0);
  color: #fff !important;
}

// Imahe uploader - delete button in image preview
.fileContainer .deleteImage {
  font-size: 14px !important;
}

.c-header-fixed {
  position: relative !important;
}

.c-main {
  padding-top: 0 !important;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;

  @media screen and (min-width: 700px) {
    .full-window-height {
    min-height: calc(100vh - 154px);
    margin-bottom: 0;
    }
  }
}

.center-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

// Orders states items in sidebar made smaller and denser
ul.c-sidebar-nav {
    .orders-state-item.c-sidebar-nav-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .c-sidebar-nav-link {
      font-size: 0.75rem;
      padding: 0.3rem 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }
  }
}

.p-multiselect {
  min-width: 13rem;
  height: 33px;
  font-size: 13px !important;
  font-family: inherit !important;
}

// row table border light theme
.MuiTableCell-root {
  border-bottom: 1px solid rgba(81, 81, 81, 0.12) !important;
}

// row table border dark theme
.c-dark-theme .MuiTableCell-root {
  border-bottom: 1px solid rgba(81, 81, 81, 0.5) !important;
}

.header-item-dropdown {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  color: rgba(0, 0, 21, 0.5);
}

.c-dark-theme .header-item-dropdown {
  color: rgba(255, 255, 255, 0.75);
}

.Orders,
.Transfers,
.MailchimpContent,
.Administration,
.CollectionsLists {
  .MuiToolbar-root {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 0.5rem;
  }

  .orders-table-bubble {
    color: red;
    margin-left: 10px;
  }

  .material-icons.closed,
  .material-icons.paid {
    font-size: 20px;
  }

  .copy-external-number {
    margin-left: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .copy-external-number:active {
    background: rgba(105, 105, 105, 0.3);
    border-radius: 3px;
    padding: 2px;
  }

  .OrdersList,
  .TransfersList,
  .AdministrationContent,
  .Administration-PrintersList,
  .CollectionListsList,
  .AddedBalikobotOrdersLists,
  .RatingBeersWithWebsiteList {
    padding-right: 3px;

    .card:first-of-type {
      box-shadow: none;
    }

    // to hide orders table empty header
    .MuiToolbar-root {
      display: none;
			animation: scale-display--reversed .3s;
      animation-fill-mode:forwards;
    }

    // Hide title and spacer in table header, only actions .material-icons
    // after rows selections will be shown
    .show-bulk-actions-toolbar .MuiToolbar-root {
      display: flex !important;
      background: rgba(0, 0, 0, 0.1);
      animation: scale-display 0.5s;
      margin-bottom: 10px;
      min-height: auto !important;

      .material-icons {
        color: #321fdb;
      }
    }

    @keyframes scale-display {
      0% {
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
      }

      100% {
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }

    // to show orders table footer
    .MuiTableRow-root.MuiTableRow-footer .MuiToolbar-root{
      display: flex;
    }

    .card {
      border: none;
      .card-body {
        .col {
          padding-left: 0;
        }
      }
    }

    .card-body {
      padding: 0;
    }

    .MuiTableCell-sizeSmall {
      padding: 4px;
    }

    .disabled-looking-button {
      cursor: default;
    }

    .disabled-looking-button:hover {
      background: #20202a;
      border-color: #20202a;
    }

    .shop-option-height {
      .input-group-text {
        max-width: 28px;
        padding: 0.3rem;
      }
      // position: absolute;
      // top: 5px;
      // right: -7px;
    }

    .shop-option-checkbox {
      // position: absolute;
      // top: 5px;
      // right: -7px;
    }

    .shop-option-input {
      max-width: 120px;
      height: 30px;
    }

    .shop-option-height,
    .button-selectes-height {
      height: 30px;
    }

    .shop-option-col {
      width: 100px;
      max-width: 100px;
      padding-right: 0 !important;
      padding-left: 15px !important;

      input, .input-group-prepend {
        cursor: pointer;
      }

      &.shop-type-checked {
        .shop-option-input {
          background-color: rgba(70, 56, 194, 0.18);
          border: 1px solid rgba(70, 56, 194, 0.55);
          border-left: 0;
        }

        .input-group-prepend {
          border: 1px solid rgba(70, 56, 194, 0.55);
          border-right: 0;
        }
      }

    }

    .select-dropdown {
      button {
        line-height: 1;
      }

      button.disabled-looking-button {
        padding-top: 0.18rem;
      }
    }

    .row-info-dragged {
      position: fixed;
      z-index: 10000;
    }

  }

  .MailchimpOrdersList {
    .MuiToolbar-root {
      display: none;
    }

    // to show orders table footer
    .MuiTableRow-root.MuiTableRow-footer .MuiToolbar-root{
      display: flex;
    }
  }



  .OrderDetail,
  .TransferDetail,
  .CollectionListsListDetail,
  .AdministrationPersonalSettings,
  .trasnfer-store-cards-modal,
  .create-transfer-item-modal,
  .order-store-cards-modal,
  .store-cards-modal {
    padding-left: 3px;

    .block-buttons,
    .block-order-information,
    .block-messages {
      margin-bottom: 0.8rem;

      button {
        margin-right: 10px;
      }

      .card-body {
        padding: 0.8rem;
      }

      .message {
        margin-top: -10px !important;
        .card-header {
          padding: 0.2rem 0.9rem;
        }
        .card-body {
          padding: 0.3rem 0.9rem;
        }
      }
    }

    .block-order-information.card {
      margin-top: -9px;
    }

    .block-messages {
      background: transparent !important;
      box-shadow: none !important;
      .card {
        margin-top: 17px;
      }
    }
    .products-table,
    .transfer-items-table,
    .store-cards-table {
      margin-bottom: 25px !important;
      .card-body {
        padding: 0;
      }
      box-shadow: none !important;

      .MuiTableCell-sizeSmall {
          padding: 3px;
      }

      .card {
        border: none;
        margin-bottom: 0;

        .card-body {
          padding: 0;
          .col {
            padding: 0;
          }
        }
      }

      // to hide orders table empty header
      .MuiToolbar-root {
        display: none;
      }

      // to show orders table footer
      .MuiTableRow-root.MuiTableRow-footer .MuiToolbar-root{
        display: flex;
      }

      .MuiButtonBase-root .MuiIconButton-label .material-icons {
        font-size: 18px;
        padding: 0;
      }

      .MuiIconButton-sizeSmall {
        padding: 0;
      }

    }

    .products-table .MuiTableCell-sizeSmall {
      padding: 0;
    }

    .card {
      .info-expand {
        &.card-header {
          padding: 0.3rem 0.5rem 0.3rem 0.7rem;
          margin-bottom: 0;
          border-bottom: 0;
        }
        cursor: pointer;
      }
      margin-bottom: 0.6rem;
    }

    .info-collapse:first-of-type {
      padding-bottom: 0;
    }

    .info-collapse:nth-of-type(2) {
      padding-top: 0;
    }

    .info-collapse .info-expand.card-header {
      padding-bottom: 0;
    }

    .info-collapse .table-sm th,
    .info-collapse .table-sm td {
      padding: 0;
    }

    .info-collapse .table th,
    .info-collapse .table td {
      border: 0;
    }

    table.transfer-detail-items-table td {
      padding: 0;
      border: 0;
    }
  }
}

.CollectionListsListDetail .modal-dialog {
  margin-top: 200px;
}

.CollectionListsList,
.AddedBalikobotOrdersLists,
.RatingBeersWithWebsiteList {
  .c-switch-slider {
    z-index: 0;
  }

  .p-datatable .p-datatable-footer {
    padding: 0 !important;
    text-align: center;
    font-weight: normal;
    font-size: smaller;
    background: white;
  }
}
.transfer-action-button-line-height,
.action-button-line-height {
  line-height: 22px;
}

.transfer-items-table .MuiTableCell-sizeSmall {
  padding: 1px !important;
}

// .store-cards-table {
//   .MuiTableRow-root .MuiButtonBase-root:first-of-type .MuiIconButton-label .material-icons:not(.MuiTableRow-footer.MuiTableRow-root .MuiButtonBase-root:first-of-type .MuiIconButton-label .material-icons) {
//     color: green;
//   }
// }

.OrderDetail {
  // Plus sign in order detail table action
  .MuiButtonBase-root:first-of-type .MuiIconButton-label .material-icons {
    color: green;
  }

  // Minus sign in order detail table action
  .MuiButtonBase-root:nth-of-type(2) .MuiIconButton-label .material-icons {
    color: red;
  }

  // Van icon in order detail table action
  .MuiButtonBase-root:nth-of-type(3) .MuiIconButton-label .material-icons {
    color: rgba(0, 0, 0, 0.45);
    margin-left: 13px !important;
  }

  table.products-items-table td {
    padding: 0;
    border: 0;
  }

  .products-items-table-totals {
    background: #f1f1f1;
    margin-right: 0;
    margin-left: 0;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.order-states-modal,
.orders-transportation-types-modal {
  .modal-header,
  .modal-footer {
    border-color: #ffffff;
  }

  .modal-body {
    padding-top: 0;
  }

  h6 {
  color: #321fdb;
  font-size: 1rem;
  }

  .orders-state:hover,
  .orders-trans-type:hover {
    border: 2px dotted #d6d0ff;
    border-radius: 10px;
    background: #f7f6ff;
    margin: -2px;
    cursor: pointer;
  }

  .orders-state.active,
  .orders-trans-type.active {
    border: 2px dotted #796cec;
    border-radius: 10px;
    background: #f7f6ff;
    margin: -2px;
    cursor: default;
  }
}

// change color for dark theme
.c-dark-theme .order-states-modal,
.c-dark-theme .orders-transportation-types-modal {
  .modal-header,
  .modal-footer {
    border-color: #2c2c34;
  }

  h6 {
  color: #8f88ca;
  }

  .orders-state:hover,
  .orders-trans-type:hover {
    border: 2px dotted #4e4e5d;
    background: #2e2e3a;
  }

  .orders-state.active,
  .orders-trans-type.active {
    border: 2px dotted #796cec;
    background: #313144;
  }
}

.OrdersList {
  th.MuiTableCell-head,
  tbody.MuiTableBody-root {
    font-size: 90%;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight,
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
    background: transparent;
  }
}

// to hide table empty header
.trasnfer-store-cards-modal .store-cards-table .MuiToolbar-root,
.StoredCardsList .MuiToolbar-root,
.ProductInOrdersList .MuiToolbar-root {
  display: none;
}

// to show table footer
.trasnfer-store-cards-modal .store-cards-table .MuiTableRow-root.MuiTableRow-footer .MuiToolbar-root,
.StoredCardsList .MuiTableRow-root.MuiTableRow-footer .MuiToolbar-root,
.ProductInOrdersList .MuiTableRow-root.MuiTableRow-footer .MuiToolbar-root {
  display: flex;
}

.ProductInOrdersList .p-multiselect-items-wrapper {
  max-height: 400px !important;
}

.StoredCardsList .card-body {
  padding: 10px;

  & > .col {
    padding: 3px;
  }
  .MuiTableCell-head {
    padding: 0 !important;
  }
  .MuiTableBody-root .MuiTableCell-body {
    padding: 3px !important;
  }
}

.StoredCardsList {
  .sold-out-option-checkbox {
    position: absolute;
    top: 5px;
    left: 65px;
  }

  .sold-out-option-input {
    max-width: 120px;
    height: 30px;
  }

  .sold-out-option-height,
  .button-selectes-height {
    height: 30px;
  }

  .sold-out-option-col,
  .sold-out-option-colfalse.col {
    width: 175px;
    max-width: 175px;
    padding-right: 0 !important;
    padding-left: 15px !important;

    input, .input-group-prepend {
      cursor: pointer;
    }

    &.sold-out-type-checked {
      .sold-out-option-input {
        background-color: rgba(70, 56, 194, 0.18);
        border: 1px solid rgba(70, 56, 194, 0.55);
        border-left: 0;
      }

      .input-group-prepend {
        border: 1px solid rgba(70, 56, 194, 0.55);
        border-right: 0;
      }
    }

  }

  // Hide title and spacer in table header, only actions .material-icons
  // after rows selections will be shown
  .show-bulk-actions-toolbar .MuiToolbar-root {
    display: flex !important;
    background: rgba(0, 0, 0, 0.1);
    animation: scale-display 0.5s;
    margin-bottom: 10px;
    min-height: auto !important;
    flex-direction: row-reverse;
    margin-bottom: 0;

    .material-icons {
      color: #321fdb;
    }
  }

  @keyframes scale-display {
    0% {
      opacity: 0;
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    100% {
      opacity: 1;
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
}

.MailchimpOrdersList > .card > .card-body {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.mailchimp-filter > .card > .card-body,
.filters-card > .card > .card-body {
  padding: 0.25rem !important;
}

.mailchimp-filter > .card {
  margin-bottom: 0.6rem;
}

.boxed-filter.card > .card-body {
  padding: 0.75rem !important;
}

.boxed-filter.card > .card {
  margin-bottom: 0.6rem;
}

.Administration-PrintersList > .card > .card-body {
  padding: 15px;
}

.Administration-PrintersList .MuiTablePagination-input {
  display: none;
}

.search-field button svg {
  transform: translate(0, -4px);
}

// Dark theme version for van icon in products table in order detail
.c-dark-theme .Orders .OrderDetail .products-table .MuiButtonBase-root:nth-of-type(3) .MuiIconButton-label .material-icons {
  color: rgba(255, 255, 255, 0.6);
}

.orders-state-item .c-sidebar-nav-link.c-active {
  background:rgba(255, 255, 255, 0.13);
}

.c-dark-theme .orders-state-item .c-sidebar-nav-link.c-active {
  background:rgba(255, 255, 255, 0.13);
}

.c-dark-theme .products-items-table-totals {
  background: #1e1e29;
}

.c-dark-theme select option { color: #4e4e4e; }

.card-no-border,
.MailchimpOrdersList .card {
  border-color: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: none;
}

.c-dark-theme .card-no-border,
.c-dark-theme .MailchimpOrdersList .card {
  border-color: #23242d;
}

html:not([dir="rtl"]) .orders-state-item .c-sidebar-nav-icon:first-child {
  margin-left: -2.2rem;
}

.orders-state-item .c-sidebar-nav-icon {
  padding-left: 17px;
}

.set-block {
  display: block !important;
}

.no-show {
  display: none;
}

.prosek-badge,
.orange-orders-count {
    color: #ffffff !important;
    background-color: #f5951e !important;
}

.react-contexify {
  z-index: 20000 !important;
  border-radius: 3px !important;
  padding: 0 !important;
}

// Hide empty rows in the last page of table if data length < pageSize
tbody tr.MuiTableRow-root:not([index]) {
  display: none !important;
}

.icon-with-badge {
  left: 20%;
  margin-left: 0;
  position: absolute;
  top: 25%;
  margin-top: -10px;
}

.on-drop-order-state {
  font-weight: bold;
  background: linear-gradient(270deg, #f26522ab, #2128338c) !important;
}

.sync-dot-danger {
  color: #e55353;
  transform: translate(0, 4px);
  font-size: 15px;
  cursor: pointer;
}

.sync-dot-success {
  color: #2eb85c;
  transform: translate(0, 4px);
  font-size: 15px;
  cursor: default;
}

.sync-column-width {
  min-width: 170px;
}

.block-buttons .btn {
  margin-bottom: 5px;
}

.ProductInOrdersList {
  .p-multiselect .p-multiselect-label {
    line-height: 14px;
  }

  .p-multiselect-panel .p-multiselect-header {
    font-size: 90%;
    padding: 0.35rem 0.8rem;
  }

  ul.p-multiselect-items {
    font-size: 90%;
    li {
      padding: 0.35rem 0.8rem;
    }
  }
}

.p-datatable {
  font-size: 92% !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0 5px !important;

  @include for-size(tablet-landscape-up) {
    padding: 0 3px !important;
  }
  @include for-size(tablet-portrait-up-only) {
    padding: 0 2px !important;
  }
}
.p-datatable .p-datatable-thead > tr > th {
  padding: 5px !important;
}

.p-datatable {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
              0px 2px 2px 0px rgba(0, 0, 0, 0.14),
              0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  .order-row-selected,
  .transfer-row-selected,
  .collection-list-selected,
  .balikobot-order-selected,
  .selected-row-highlight {
    background: #d4dce8 !important;
  }

  .p-datatable-tbody tr:nth-child(2n) {
    background: #fbfbfb;
  }
}


.p-datatable .p-datatable-header {
  padding: 0 !important;
  background: white !important;
}

.p-checkbox .p-checkbox-box {
  width: 15px !important;
  height: 15px !important;
}

.p-paginator {
  font-size: 90%;
  transform: scale(0.8);
  padding: 2px;
  margin: 0;
  border-bottom: 0;
}

.p-datatable .p-datatable-tbody > tr:not(.p-highlight):hover,
.row-hover {
  background: #e9ecef !important;
  color: #495057 !important;
}

.p-datatable .p-datatable-tbody > tr {
  cursor: pointer !important;
}

.c-dark-theme {
  .p-datatable .p-datatable-tbody > tr > td {
    background: #31313a !important;
    border-color: #3f3f43 !important;
    color: white;
  }
  .p-datatable .p-datatable-thead > tr > th {
    background: #1e1e28 !important;
    border-color: #3f3f43 !important;
    color: rgba(255, 255, 255, 0.53);
  }

  .p-datatable .p-datatable-header {
    background: #31313a !important;
    border-color: #1e1e28 !important;
  }

  .p-datatable .p-datatable-tbody > tr {
    border-color: #3f3f43 !important;
  }

  .p-datatable-tbody tr:nth-child(2n) td {
    background: #2c2c34  !important;
  }

  .p-datatable .p-datatable-tbody > tr.order-row-selected > td,
  .p-datatable .p-datatable-tbody > tr.transfer-row-selected > td,
  .p-datatable .p-datatable-tbody > tr.collection-list-selected > td {
    background: #1e1e29 !important;
  }

  .p-paginator {
    background: #23242d;
    border: none;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #44444c;
    color: #8f9aa5;
  }
}

.CollectionListsList .p-datatable .p-datatable-tbody > tr > td,
.TransfersList .p-datatable .p-datatable-tbody > tr > td,
.AddedBalikobotOrdersLists .p-datatable .p-datatable-tbody > tr > td,
.RatingBeersWithWebsiteList .p-datatable .p-datatable-tbody > tr > td {
  padding: 5px 10px !important;
}

.TransfersList .p-datatable .p-datatable-tbody > tr > td.transfer-list-column-left-pad {
  padding-left: 5px !important;
}

.TransfersList .p-datatable .p-datatable-tbody > tr > td.transfer-list-column-right-pad {
  padding-right: 5px !important;
}

.StoreCardsConsumptionTaxList .p-datatable .p-datatable-tbody > tr > td {
  padding: 5px 10px !important;
}

.IPAddressesList .p-datatable,
.StoreCardsConsumptionTaxList .p-datatable {
  .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0 !important
  }
}

.c-app {
  background-color: #ffffff
}

.c-app.c-dark-theme {
  background-color: #2c2c34 !important;
}

.document-proccessing-notification,
.airshop-notification,
.custom-bottom-loading-notification {
  // not working in notifications settings, set here
  width: 500px !important;
  text-align: center;
  // due to width issues not centered, solved here, (gotten as 500 - 350 (default)) / 2
  transform: translate(-75px, 0);
}

.hover-while-drag {
  color: #fff !important;
  background: #321fdb73 !important;
  cursor: default !important;
}

tr.row-on-drag td {
  background: rgba($color: #321fdb, $alpha: 0.15) !important;
}

.c-app.c-dark-theme {
  tr.row-on-drag td {
    background: #6b3a26 !important;
  }

}

.breadcrumb-item a.active {
  color: #f26522
}

.c-sidebar-nav-title {
  font-size: 90%;
}

.c-sidebar-nav-title:first-of-type {
  margin-top: 0;
}

// Order states sidebar items folders icons colors
.c-sidebar-nav-item  i.state-icon {
  &.blue {
    color: #6699cc !important;
  }
  &.orange {
    color: #ff9933 !important;
  }
  &.white {
    color: #f7f9f9 !important;
  }
  &.red {
    color: #ff6666 !important;
  }
  &.base {
    color: inherit !important;
  }
}

// Draggable icon
.OrdersList {
  .p-table-reorderablerow-handle.pi {
  font-size: 25px;
  opacity: 0.5;
  }
  .p-checkbox.p-component {
    transform: translate(0, 2px);
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none !important;
    border: 2px solid #ced4da !important;
  }
}

.c-app {
  --primary: #215090 !important;
}

.btn-primary,
.btn-primary:link,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus,
.btn-primary.focus,
.show > .btn-primary.dropdown-toggle {
  background-color: #215090;
}

.btn-primary:hover {
  background-color: #1f60bb;
}

.p-datatable .p-datatable-thead > tr > th.eans-border-right {
  border-right-width: 22px;
  border-right-color: #f8f9fa !important;
}

.c-dark-theme .p-datatable .p-datatable-thead > tr > th.eans-border-right {
  border-right-color: #1e1e28 !important;
}


.p-tooltip.storecards-ean-del-confirm,
.p-tooltip.custom-popup-confirm {
  width: auto !important;
  max-width: 350px !important;
}

.storecards-ean-del-confirm .p-tooltip-text,
.custom-popup-confirm .p-tooltip-text {
  padding: 0.4rem 1.25rem !important;
  width: auto !important;
  max-width: 350px !important;
}

.p-tooltip.adm-abra-commands-col {
  width: auto !important;
  max-width: 650px !important;
}

.wide-tooltip {
  width: auto !important;
  min-width: 600px;
  max-width: 650px !important;
}

.p-tooltip-text .adm-abra-commands-col-text,
.wide-tooltip-text  {
  padding: 0 0.2rem !important;
  width: auto !important;
  max-width: 650px !important;
  font-size: 12px;
  display: block;
  word-break: break-all;
}

.material-icons-hover {
  cursor: pointer;
}

.material-icons-hover:hover {
  opacity: 0.7;
}

.StoreCardsConsumptionTaxList,
.IPAddressesList {

  .p-checkbox.p-component {
    transform: translate(0, 3px);
  }

  .p-inputtext {
    font-size: 12px !important;
    padding: 0.25rem 0.5rem !important;
    border-color: #e4e7ea !important;
    max-width: 50px;
  }
}

.IPAddressesList.card:first-of-type,
.AppUsersList.card:first-of-type,
.AbraCommandsList.card:first-of-type {
  box-shadow: none !important;
}

.AppUsersList {
  .p-datatable .p-datatable-tbody > tr > td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
}

.branch-autocomplete {
  padding: 0;
  width: 100%;

  input {
    width: 100% !important;
    font-size: 0.76563rem !important;
    height: calc(1.5em + 0.5rem + 2px) !important;
  }
}

.inline-form-field-element {
  padding: 5px;
  font-size: 0.76563rem !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
}

.cash-on-delivery-switch {
  // width: 60px;
  display: inline-block;
  padding-top: 2px;
}

.cash-on-delivery-field-append {
  display: inline-block;
  transform: translate(0, -1px);
}

.cash-on-delivery-field-prepand {
  display: inline-block;
  // height: 28px;
  // .balikobot-prefill-form label {
  //   height: 28px;
  // }
}

.cash-on-delivery-input {
  display: inline-block;
  width: calc(100% - 50px);
  height: 29px;
  input {
    font-size: 0.76563rem !important;
    height: 29px;
  }
}

.balikobot-prefill-form {
  .form-group {
    margin-bottom: 4px !important;
  }

  label {
    margin-bottom: 0;
  }

}

.p-autocomplete-panel {
  transform: scale(0.8) !important;
}

.IPAddressesList {
  // Switch for deny-ip state to change color
  .c-switch-opposite-success .c-switch-input:not(:checked) + .c-switch-slider {
    background-color: #fff;
    border-color: #e55353;
  }

  .c-switch-label.c-switch-sm .c-switch-input:not(:checked) + .c-switch-slider::before {
    background: #e55353;
  }

  .c-switch-label.c-switch-sm .c-switch-input:not(:checked) + .c-switch-slider::after {
    color: #e55353;
  }

  // Deny ip row color
  tbody tr.deny-ip {
    background: rgba(255, 7, 7, 0.04) !important;
  }

  .ip-address .form-control {
    display: inline-block;
    width: 150px;
  }

}

.create-new-ip-address-modal,
.form-modal {
  .form-text {
    margin-top: 0;
    margin-bottom: 10px;
  }
}


.c-dark-theme .StoreCardsConsumptionTaxList .p-inputtext,
.c-dark-theme .IPAddressesList .p-inputtext {
  color: #ffffff;
  background: #46464e !important;
  border-color: #505057 !important;
}

.store-cards-edit-modal {
  label {
    margin-bottom: 0;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }

    span.p-inputnumber.p-component {
      display: block;
      input {
        width: 100%;
      }
    }
}

// Redundant styles but somehow necessary for the logic to work
.orders-sidebar-nav-helper1,
.orders-sidebar-nav-helper2,
.orders-sidebar-nav-helper3,
.orders-sidebar-nav-helper4,
.orders-sidebar-nav-helper5,
.orders-sidebar-nav-helper6 {
  color: rgba(255, 255, 255, 0.8) !important;
}

hr {
  @include for-size(tablet-landscape-up) {
    margin-top: 0.45rem;
    margin-bottom: 0.45rem;
  }
}

.error-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}

.success-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #147936;
}

.order-states-modal {
  @include for-size(tablet-landscape-up) {
    h6 {
      margin: 0;
      margin-bottom: 0.3rem;
    }
    .modal-header {
      padding-top: 0.45rem;
      padding-bottom: 0.45rem;
    }
    .modal-body {
        padding-bottom: 0;
    }
    .modal-footer {
        padding-top: 0.45rem;
        padding-bottom: 0.45rem;
    }
  }
}

.OrdersList {
  @include for-size(tablet-portrait-up-only) {
    .p-paginator {
      padding: 0;

      .p-paginator-pages .p-paginator-page {
        min-width: 1.5rem !important;
      }
    }
  }
}

.TargetsOneMenuList,
.BarrelsCommoditiesList {
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }

  .form-group {
    margin-bottom: 0.5rem !important;
    label {
      margin-bottom: 0.5rem !important;
    }
  }

  .form-group:first-of-type {
    margin-top: 10px;
  }

//   .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
//     background: #e8e8e8;
// }

  .expanded-row {
    margin: 10px 0;
  }

  .expanded-row,
  .expanded-row:hover {
    cursor: default;
    background: rgba(0,0,0,0.08) !important;
  }

}

.filters-group {
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.15rem 0.4rem !important;
  }
  .card {
    margin-bottom: 1rem;
  }
}

.filters-col.col-xl-6:nth-child(even) {
  padding-left: 5px !important;
}

.filters-col.col-xl-6:nth-child(odd) {
  padding-right: 5px !important;
}

.errored-abra-commands .list-group-item {
  padding: 7px 10px !important;
  margin-bottom: 4px !important;
}

.card-header-actions.clear-button {
  margin-top: -11px;
}

.autocomplete-sm {
  padding: 0;
  width: 100%;

  input {
    width: 100% !important;
    font-size: 0.76563rem !important;
    height: calc(1.5em + 0.5rem + 2px) !important;
    border-color: #e4e7ea;
  }
}

.autocomplete-regular.form-control {
  border: none;
}

.autocomplete-regular {
  padding: 0;
  width: 100%;

  input {
    width: 100% !important;
    font-size: 0.76563rem !important;
    height: 35px !important;
    border: 1px solid #e4e7ea;
  }
}

.light-background {
  background-color: white !important;
}

.pencil-hoverable {
  opacity: 0.3;
}

.pencil-hoverable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.MallTarget .p-filter-column {
  padding: 2px !important;
}

.ql-editor {
  min-height: 350px;
}

.ql-container.ql-snow {
  border-color: rgba(164, 164, 164, 0.3) !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.KegsCustomersList {
  span.badge {
    font-size: 85%;
  }
}

.green-tab {
  background: #26994d;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  .nav-link {

    color: white;
  }
}

.c-header-nav-link.active {
  color: #f26522 !important;
}

.orders-search-filter-field .btn-sm .c-icon,
.transfers-search-field .btn-sm .c-icon {
  transform: translateY(0);
}

.CollectionListsList .p-paginator-page.p-paginator-element.p-link {
  border-radius: 3px;
  background: #e9ecef;

  &:not(.p-highlight):hover {
    opacity: 0.7;
  }
}

// React summernote settings
.note-popover .popover-content .note-style .dropdown-style h1, .note-popover .popover-content .note-style .dropdown-style h2, .note-popover .popover-content .note-style .dropdown-style h3, .note-popover .popover-content .note-style .dropdown-style h4, .note-popover .popover-content .note-style .dropdown-style h5, .note-popover .popover-content .note-style .dropdown-style h6, .note-popover .popover-content .note-style .dropdown-style p, .note-editor .note-toolbar .note-style .dropdown-style h1, .note-editor .note-toolbar .note-style .dropdown-style h2, .note-editor .note-toolbar .note-style .dropdown-style h3, .note-editor .note-toolbar .note-style .dropdown-style h4, .note-editor .note-toolbar .note-style .dropdown-style h5, .note-editor .note-toolbar .note-style .dropdown-style h6, .note-editor .note-toolbar .note-style .dropdown-style p, .note-editor .note-toolbar .note-style .dropdown-style blockquote,
.dropdown-fontname li a {
  color: black;
}

.note-icon-caret {
  display: none;
}


.SentEmails .MuiToolbar-root,
.ReceivedEmails .MuiToolbar-root {
  /* Search field placed in title in table */
  div[class^="MTableToolbar-title"] {
    width: 100% !important;
  }

  .form-group {
  margin-bottom: 0;
  }
}

/* Sent emails */
.email-wrapper .mail-list-container {
  border-left: 1px solid #e9e9e9;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.email-wrapper .mail-list-container a {
  text-decoration: none;
}

.email-wrapper .mail-list-container .mail-list {
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  width: 100%;
}

.email-wrapper .mail-list-container .mail-list:last-child {
  border-bottom: none;
}

.email-wrapper .mail-list-container .mail-list .content {
  width: 83%;
  padding-left: 0;
  padding-right: 0;
}

.email-wrapper .mail-list-container .mail-list .details {
  width: 5.5%;
}

/* Message Content */
.email-wrapper .message-body .sender-details {
  padding: 20px 15px 1rem;
  border-bottom: 1px solid #e9e9e9;
  display: -webkit-flex;
  display: flex;
}

.email-wrapper .message-body .sender-details .details {
  padding-bottom: 0;
}

.email-wrapper .message-body .sender-details .details .msg-subject {
  font-weight: 600;
}

.email-wrapper .message-body .sender-details .details .sender-email {
  margin-bottom: 20px;
  font-weight: 400;
}

.email-wrapper .message-body .message-content {
  padding: 1rem 15px;
}

.SentEmailsTable .MuiTableRow-root.MuiTableRow-hover:hover {
  background: #f4f7fd;
}

.center-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.product-in-orders-list .p-multiselect-items-wrapper {
  max-height: 500px !important;
}

.favorite-combinations a {
  text-decoration: underline;
  color: #6699cc;
}

.whole-word-on-line {
  a {
    white-space: nowrap;
  }
}

.sub-field-button {
  height: 21px;
  padding: 0 6px;
  transform: translateY(-5px);

  a {
    color: inherit;
  }
}

.suborder.badge-primary {
  background-color: #215090 !important;
}

.sub-website-button-link a {
 color: white;
}


// Context submenu padding fix
.react-contexify__item__content > .react-contexify__item > .react-contexify__item__content {
  padding: 0;
}

