// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui-pro/scss/coreui.scss';

// Date picker component
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

// Translations editor
@import 'summernote';

// If you want to add something do it here
@import 'custom';

$theme-colors: (
  "primary": red,
);

