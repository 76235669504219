span.translation__content, .note-editor {
  @import url("https://fonts.googleapis.com/css?family=Titillium+Web:400,700&amp;subset=latin-ext");
  $red: #b70016;

  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  h1, h2, h3, h4, h5, h6 {
    font-family: "Titillium Web", sans-serif;
    line-height: 1.1;
  }
  h1, h2, h3, h4, h5, h6 {
    color: $red;
  }
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 24px;
  }
}
